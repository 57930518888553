









import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsBreadCrumb extends Vue {
    @Prop() private breadCrumbSource!:string;
    get breadCrumb () {
      if (this.breadCrumbSource) return this.$store.state[this.breadCrumbSource];
      else throw new Error('產品面包屑組件：要求你設置breadCrumbSource數據源');
    }
}
