






















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import YouWouldLike from '@/model/youWouldLike';
// import InsPage from '@/components/base/pc/InsPage.vue';
@Component({
  components: {
    InsProductList: () => import(/* webpackChunkName: "account" */ '@/components/business/pc/product/InsProductList.vue'),
    InsPage: () => import(/* webpackChunkName: "account" */ '@/components/base/pc/InsPage.vue')
  }
})
export default class InsProductSearch extends Vue {
  proList: YouWouldLike[] = []; // 产品数据
  currentPage: number = 1; // 当前页
  pageSize: number = 2; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数

  attrs: object[] = []; // 选中的产品属性数组
  searchCatalogs: number[] = []; // 选中的产品目录数组
  searchType: number = 1; // 搜索类型（0 => 叠加，1 => 筛选）

  private loadingFlag:boolean = false;
  private tips:boolean = true;
  // 搜索关键词
  get searchKey () {
    let a = this.$route.params.key ? this.$route.params.key : '+';
    if (a === '+') {
      return '';
    } else {
      return a;
    }
  }

  // 产品高级搜索
  productSearch (key, NotAdd?) {
    this.$Api.product.search({
      Key: key,
      PageInfo: {
        Page: this.currentPage,
        PageSize: this.pageSize,
        SortName: 'code',
        SortOrder: 'asc'
      },
      CatIdS: this.searchCatalogs,
      Attrs: this.attrs,
      Type: this.searchType
    }).then((result) => {
      if (!NotAdd) {
        this.proList = this.proList.concat(result.YouWouldLike);
      } else {
        this.proList = result.YouWouldLike;
      }
      this.totalRecord = result.TotalRecord;
      this.$HiddenLayer();
    });
  }

  // handleAttrsChange (val) {
  //   this.attrs = val;
  //   this.productSearch(this.searchKey);
  // }

  // handleCatalogsChange (val) {
  //   this.searchCatalogs = val;
  //   this.productSearch(this.searchKey);
  // }

  handleCurrentChange (val) {
    this.currentPage = val;
    this.productSearch(this.searchKey);
  }
  created () {
    this.productSearch(this.searchKey);
  }

  @Watch('searchKey', { deep: true })
  onSearchKeyChange () {
    this.productSearch(this.searchKey, true);
  }
  get key () {
    return this.$store.state.searchKey;
  }
  @Watch('key')
  onKeyChange () {
    // this.productSearch(this.$store.state.searchKey);
    // this.$router.push('/product/search/' + this.key);
    this.productSearch(this.searchKey, true);
  }

  loading (e) {
    if (this.$route.name !== 'productSearch') return;
    if (this.tips) {
      this.loadingFlag = true;
      // this.LoadingInstance = Loading.service({
      //   target: this.$refs.load as any,
      //   fullscreen: false,
      //   spinner: 'el-icon-loading'
      // });
      setTimeout(() => {
        this.load();
        this.loadingFlag = false;
        // this.LoadingInstance.close();
      }, 2000);
    }
  }
  load () {
    if (this.totalRecord > this.proList.length) { this.handleCurrentChange(++this.currentPage); } else { this.tips = false; }
  }
  mounted () {
    addEventListener('scroll', this.scroll);
  }
  scroll (e) {
    if ((window.scrollY + window.innerHeight) === document.body.clientHeight || (window.scrollY + window.innerHeight - 17) === document.body.clientHeight) {
      this.TShake(this.loading, 250);
    }
  }
  destoryed () {
    removeEventListener('scroll', this.scroll);
  }
  delay = 0;
  TShake (fn, d) {
    if (!(fn instanceof Function)) return;
    let timeout = d || 1000;
    if (this.delay === 0) { this.delay = setTimeout(fn, timeout); } else { clearTimeout(this.delay); this.delay = setTimeout(fn, timeout); }
  };
}
