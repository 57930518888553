




































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper/src';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
@Component({ components: { swiper, swiperSlide, Viewer } })
export default class PkProductSwiper extends Vue {
  @Prop() private readonly imgList!: string[];
  @Prop() private readonly ProductTitleName!: string[];
  private currentIndex = 0;
      swiperOption: object = {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      };
  $viewer: any;
  get isClick () {
    return this.$store.state.isClick;
  }
  @Watch('imgList', { deep: true })
  imgListChange () {
    if (this.AttrImg === '') {
      this.$store.dispatch('setAttrImgList', this.imgList[0][0]);
    }
  }
  get AttrImg () {
    return this.$store.state.AttrImgList;
  }
  inited (Viewer) {
    this.$viewer = Viewer;
  }
  initedN (Viewer) {
    this.$viewer = Viewer;
  }
  viewImg (val) {
    this.$viewer.view(val);
  }
  RetrunImg () {
    this.$store.dispatch('isClick', false);
  }
}
