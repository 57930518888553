















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import YouWouldLike from '@/model/youWouldLike';
@Component({ components: {
  inPreview: () => import(/* webpackChunkName: "product" */ '@/components/business/pc/product/InsPreviewN.vue'),
  inPanel: () => import(/* webpackChunkName: "product" */'@/components/fsBusiness/pc/product/FsPanel.vue'),
  inYouWouldLike: () => import(/* webpackChunkName: "product" */ '@/components/business/pc/product/InsYouWouldLike.vue'),
  InsProductDescription: () => import(/* webpackChunkName: "product" */ '@/components/business/pc/product/InsProductDescription.vue'),
  insBreadcrumb: () => import(/* webpackChunkName: "product" */ '@/components/base/pc/InsBreadcrumb.vue')
} })
export default class InsProductDetail extends Vue {
  private ImgList: string[] = [];

  getProduct () {
    var that = this;
    // 获取产品详情数据
    this.$store.dispatch('setProductDetailSku', this.pc);
    this.$Api.product.GetProduct(this.pc).then((result) => {
      console.log(result, '获取详细产品');
      this.ImgList = result.AdditionalImage;
      this.$nextTick(() => {
        document.title = result.PanelDetail.Name;
      });
      this.$store.dispatch('setProductDetail', result.PanelDetail);
      this.$store.dispatch('setImgList', result.AdditionalImage);
      let Tabs = result.Tab;
      Tabs.setTabsName(this.$t('product.comments.title'));
      this.$store.dispatch('setProductDetailTab', Tabs);
      this.$HiddenLayer();
    });
  }
  mounted () {
    this.getProduct();
  }
  created () {

  }
  @Watch('pc')
  onWatchRoute (o, n) {
    console.log(this.pc);
    this.$store.dispatch('setProductDetailSku', this.pc);
    this.$Api.product.GetProduct(this.pc).then((result) => {
      this.$nextTick(() => {
        document.title = result.PanelDetail.Name;
      });
      this.$store.dispatch('setProductDetail', result.PanelDetail);
      this.$store.dispatch('setImgList', result.AdditionalImage);
      let Tabs = result.Tab;
      Tabs.setTabsName(this.$t('product.comments.title'));
      this.$store.dispatch('setProductDetailTab', Tabs);
      this.$HiddenLayer();
    });
  }
  // gotoSlider () {
  //   this.$nextTick(() => {
  //     let target = document.getElementById('tab') as HTMLElement;
  //     target.scrollIntoView();
  //   });
  // }
  get pc () {
    return this.$route.params.id;
  }
}
