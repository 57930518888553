













import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
// import inProductItem from '@/components/business/pc/product/InsProductItem.vue';
import YouWouldLike from '@/model/youWouldLike';
import Currency from '@/model/currency';
import { Loading } from 'element-ui';
@Component({ components: { inProductItem: () => import(/* webpackChunkName: "product" */ '@/components/business/pc/product/InsProductItem.vue') } })
export default class NaProductCat extends Vue {
    private catalogs = {};
    private callCatalogs!:Promise<any>;
    private loadingFlag:boolean = false;
    private allItems: YouWouldLike[] = [];
    private CurrentPage: number = 1;
    private TotalPage: number = 1;
    private TotalRecord: number = 0;
    @Prop({ default: 2 }) private pageNum!: number;

    // private LoadingInstance!: any;
    private tips:boolean = true;

    get catId () {
      return this.$store.state.catId | this.$route.params.catId as any;
    }
    created () {
      this.callCatalogs = this.$Api.product.getAttrList2();
      let c = {};
      this.callCatalogs.then((result) => {
        result.Catalogs.forEach(element => {
          c[element.Id] = {
            Name: element.Name,
            Remark: element.Remark
          };
          element.Children.forEach(ce => {
            c[ce.Id] = {
              Name: ce.Name,
              Remark: ce.Remark
            };
            ce.Children.forEach(ge => {
              c[ge.Id] = {
                Name: ge.Name,
                Remark: ge.Remark
              };
            });
          });
        });
        this.catalogs = c;
        if (!this.$route.params.catId) this.$store.dispatch('setCatId', this.catalogs[0].Id);
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum,
            SortName: 'code',
            SortOrder: 'asc'
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          if (this.allItems.length % 2 !== 0) this.allItems.push(new YouWouldLike('', '', '', '', '', '', new Currency(), true));
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
        });
      });
    }
    @Watch('CurrentPage')
    onCurrentPage () {
      setTimeout(() => {
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum,
            SortName: 'code',
            SortOrder: 'asc'
          }).then((result) => {
          this.allItems = this.allItems.concat(result.YouWouldLike);
          if (this.allItems.length % 2 !== 0) this.allItems.push(new YouWouldLike('', '', '', '', '', '', new Currency(), true));
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
        });
      }, 50);
    }
    @Watch('catId')
    onStoreSearchKey () {
      this.CurrentPage = 1;
      this.$ShowLayer();
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum,
            SortName: 'code',
            SortOrder: 'asc'
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
          this.$HiddenLayer();
        });
      }, 50);
    }
    loading (e) {
      if (this.$route.name !== 'product') return;
      if (this.tips) {
        this.loadingFlag = true;
        setTimeout(() => {
          this.load();
          this.loadingFlag = false;
        }, 2000);
      }
    }
    load () {
      if (this.TotalRecord > this.allItems.length) { this.CurrentPage++; } else { this.tips = false; }
    }
    mounted () {
      addEventListener('scroll', this.scroll);
    }
    delay = 0;
    TShake (fn, d) {
      if (!(fn instanceof Function)) return;
      let timeout = d || 1000;
      if (this.delay === 0) { this.delay = setTimeout(fn, timeout); } else { clearTimeout(this.delay); this.delay = setTimeout(fn, timeout); }
    };
    scroll (e) {
      if ((window.scrollY + window.innerHeight) === document.body.clientHeight || (window.scrollY + window.innerHeight - 17) === document.body.clientHeight) {
        this.TShake(this.loading, 250);
      }
    }
    destoryed () {
      removeEventListener('scroll', this.scroll);
    }
}
