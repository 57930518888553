

























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper/src';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
// Vue.use(Viewer, {
//   defaultOptions: {
//     zIndex: 9999
//   }
// });
class ImgItem {
  Src:string;
  Virtual:boolean;
  constructor (src:string, vertual:boolean = false) {
    this.Src = src;
    this.Virtual = vertual;
  }
}
@Component({ components: { swiper, swiperSlide, Viewer } })
export default class InsPreview extends Vue {
  // data
  private mirrorShow: boolean = false;
  private currentIndex = 0;
  // private InnerItems: ImgItem[] = [];
  private ShowItems: ImgItem[] = [];
  @Prop() private readonly ProductTitleName!: string[];
  private MiddlePic = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };
SwiperOptionN: object = {
  slidesPerView: 5,
  // autoplay: 5000,
  direction: 'vertical',
  autoHeight: true,
  observer: true,
  observeParents: true,
  grabCursor: true,
  autoplayDisableOnInteraction: false,
  mousewheelControl: true
};
  private TranslateM: string = '';
  private MirrorImgM: string = '';
  private CurrentPic: string = '';
  private acceepClcik:boolean =false;
  // props
  @Prop() private readonly imgList!: string[];
  @Prop() private readonly height!: string;
  @Prop() private readonly width!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly pageNum!: number;
  $Viewer: any;
  $viewer: any;
  // computed
  get warpperStyle (): string {
    return 'width:' + this.width + ';height:' + this.height + ';' + this.styla;
  }
  created () {
    let that = this;
    document.body.addEventListener(
      'mousemove',
      () => {
        // eslint-disable-next-line
        that.mirrorShow = false;
      },
      false
    );
  }
  get AttrImg () {
    return this.$store.state.AttrImgList;
  }
  @Watch('imgList', { deep: true })
  // imgListChange () {
  //   if (this.AttrImg === '') {
  //        this.$store.dispatch('setAttrImgList', this.imgList[0][0]);
  //        this.CurrentPic = this.imgList[0][0];
  //   }
  //   this.$store.dispatch('setNormalImg', this.imgList[0][0]);
  //   this.CurrentPic = this.imgList[0][0];
  //   this.InnerItems = [];
  //   this.ShowItems = [];
  //   this.imgList.forEach(element => {
  //     this.InnerItems.push(new ImgItem(element));
  //   });
  //   while (this.InnerItems.length > 0) {
  //     this.ShowItems.push(this.InnerItems.splice(0, this.pageNum));
  //   }
  //   while (
  //     this.ShowItems.length > 0 &&
  //     this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
  //   ) {
  //     this.ShowItems[this.ShowItems.length - 1].push(
  //       new ImgItem('', true)
  //     );
  //   }
  // }
  imgListChange () {
    this.CurrentPic = this.imgList[0][0];
    // this.InnerItems = [];
    this.ShowItems = [];
    this.imgList.forEach(element => {
      this.ShowItems.push(new ImgItem(element));
    });
  }
  inited (Viewer) {
    this.$viewer = Viewer;
  }
  initedN (Viewer) {
    this.$viewer = Viewer;
  }
  viewImg (val) {
    this.$viewer.view(val);
  }
  get isClick () {
    return this.$store.state.isClick;
  }
  getImg (src, index) {
    this.CurrentPic = src;
    this.$store.dispatch('isClick', false);
    this.currentIndex = index;
  }
  mounted () {

  }
  mirrorMove (e: MouseEvent) {
    let x, y, mx, my;
    x = e.pageX - this.MiddlePic.left;
    y = e.pageY - this.MiddlePic.top;
    mx = e.pageX - (this.MiddlePic.left + this.MiddlePic.right) / 2;
    my = e.pageY - (this.MiddlePic.top + this.MiddlePic.bottom) / 2;
    this.TranslateM =
      'transform:translate(-50%,-50%) translate(' + x + 'px,' + y + 'px);';
    this.MirrorImgM =
      'transform:translate(-50%,-50%) translate(' + (-mx) + 'px,' + (-my) + 'px);';
    if (e.pageX > this.MiddlePic.right * 1.1 || e.pageX < this.MiddlePic.left * 0.9) {
      this.mirrorShow = false;
      return;
    }
    if (e.pageY > this.MiddlePic.bottom * 1.1 || e.pageY < this.MiddlePic.top * 0.9) {
      this.mirrorShow = false;
    }
  }
  previewMove (e: MouseEvent) {
    let target = e.target as HTMLImageElement;
    this.MiddlePic.top = target.offsetTop;
    this.MiddlePic.left = target.offsetLeft;
    this.MiddlePic.right = target.offsetLeft + target.width;
    this.MiddlePic.bottom = target.offsetTop + target.height;
    this.mirrorShow = true;
  }
  changeBigpic (src: string) {
    this.CurrentPic = src;
  }
  click (e: MouseEvent) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
      this.CurrentPic = target.dataset.key as string;
    }
  }
  loadError (e) {
    e.target.src = '/static/Image/proddef.jpg';
  }
}
