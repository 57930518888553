








import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsNum extends Vue {
  @Prop() private readonly label!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly min!: number;
  @Prop() private readonly max!: number;
  @Prop() private readonly size!: string;
  @Prop() private readonly lock!: boolean;
  private Max: number = 0;
  private Min: number = 1;
  @Prop() private v!:number;
  private value:number = 0;
  onValueChange () {
    this.$emit('input', this.value);
  }
  created () {
    this.value = this.v;
    this.Max = this.max !== undefined ? this.max : this.Max;
    this.Min = this.min !== undefined ? this.min : this.Min;
  }
}
