



















































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import PanelDetail from '@/model/PanelDetail';
import ProductAttr from '@/model/ProductAttr';
import Button from '@/model/Button';
import ShopCartItem from '@/model/shopCartItem';
@Component({ components: { inNum: () => import(/* webpackChunkName: "product" */ '@/components/fsBusiness/pc/base/FsNum.vue'),
  inSelect: () => import(/* webpackChunkName: "product" */ '@/components/base/pc/InsRadio.vue'),
  inButton: () => import(/* webpackChunkName: "product" */'@/components/base/pc/InsButton.vue'),
  inPrices: () => import(/* webpackChunkName: "product" */'@/components/fsBusiness/pc/base/FsPrices.vue'),
  EdProductShare: () => import(/* webpackChunkName: "product" */'@/components/edBusiness/pc/product/EdProductShare.vue') } })
export default class Panel extends Vue {
  // @Prop() private panelDetail!: PanelDetail;
  @Prop() private readonly width!: string;
  @Prop() private readonly height!: string;
  // @Prop() private readonly ProductSku!: string;
  // @Prop() private readonly Score!: string;
  @Prop() private readonly skuSource!: string;
  @Prop() private readonly panelDetailSource!: string;
  private delay = 0;
  private ProductInfor: ShopCartItem = new ShopCartItem();
  get ProductSku () {
    if (this.skuSource) return this.$store.state[this.skuSource];
    else throw new Error('產品panel組件：要求你設置sku數據源');
  }
  get panelDetail () {
    if (this.panelDetailSource) return this.$store.state[this.panelDetailSource];
    else throw new Error('產品panel組件：要求你設置panelDetail數據源');
  }
  get Score () {
    if (this.panelDetailSource) return this.$store.state[this.panelDetailSource].Score;
    else throw new Error('產品panel組件：要求你設置panelDetail數據源');
  }
  set Score (v) {

  }
  get warpperStyle (): string {
    return 'width:' + this.width + ';height:' + this.height + ';';
  }
  click (item) {
    let action = item.action;
    item.loading = true;
    if (action) {
      if (action === 'addToCart') {
        this.$Api.shoppingCart.addItem(this.ProductSku, this.ProductInfor.Qty, this.ProductInfor.Attr1, this.ProductInfor.Attr2, this.ProductInfor.Attr3)
          .then(
            (result) => {
              // Vue.prototype.$Confirm('Message', result.Message.Message);
              this.$message(result.Message.Message as string);
              item.loading = false;
            }).then(() => {
            this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
          }).catch();
      } else if (action === 'buy') {
        this.$Api.shoppingCart.addItem(this.ProductSku, this.ProductInfor.Qty, this.ProductInfor.Attr1, this.ProductInfor.Attr2, this.ProductInfor.Attr3)
          .then(
            (result) => {
              this.$router.push({ name: 'shoppingcart' });
            }).catch();
      } else if (action === 'favorite') {
        this.addFavorite().then(() => { item.loading = false; });
      }
    } else {
      Vue.prototype.$Confirm('action', Object.create(this.ProductInfor));
    }
  }
  created () {
    if (this.$route.query.addFavorite) this.addFavorite();
    console.log(this.panelDetail);
  }
  addFavorite () {
    let ps;
    if (this.panelDetail.IsFavorite) {
      ps = this.$Api.product.removeFavorite(this.ProductSku).then((result) => {
        if (result.Succeeded) {
          this.$Confirm(this.$t('Message.Message'), this.$t('product.successInRemoving'));
          this.panelDetail.IsFavorite = false;
        } else if (result.ReturnValue.Code === 1000) {
          this.$Confirm(this.$t('product.logouted'), this.$t('product.loginow'), () => { this.$router.push('/account/register?returnurl=/product/detail/' + this.ProductSku + '&otherParam=addFavorite' + encodeURIComponent('=true')); });
        }
      });
    } else {
      ps = this.$Api.product.addFavorite(this.ProductSku).then((result) => {
        if (result.Succeeded) { this.$Confirm(this.$t('Message.Message'), this.$t('product.successInAdding')); this.panelDetail.IsFavorite = true; } else if (result.ReturnValue.Code === 1000) {
          this.$Confirm(this.$t('product.logouted'), this.$t('product.loginow'), () => { this.$router.push('/account/register?returnurl=/product/detail/' + this.ProductSku + '&otherParam=addFavorite' + encodeURIComponent('=true')); });
        }
      });
    }
    return ps;
  }
  // Shake (fn) {
  //   if (!(fn instanceof Function)) return;
  //   let timeout = 1000;
  //   if (this.delay === 0) { this.delay = setTimeout(fn, timeout); } else { clearTimeout(this.delay); this.delay = setTimeout(fn, timeout); }
  // }
  changeAttr () {
    this.Shake(() => {
      this.$Api.product.checkProductStatus(this.ProductSku, this.ProductInfor.Attr1, this.ProductInfor.Attr2, this.ProductInfor.Attr3).then((result) => {
        this.panelDetail.ProductStatus = result.object0.ReturnValue;
      });
    });
  }
  @Watch('panelDetail.AttrList', { deep: true })
  onPAChange () {
    if (this.panelDetail.AttrList.length > 0 && this.panelDetail.AttrList[0].length === 0) this.changeAttr();
  }
}
