


























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ProductAttr from '@/model/ProductAttr';
@Component
export default class InsRadio extends Vue {
  // data
  private Value: string = '';
  private RealValue: string = '';
  private Current: string = '0';
  // props
  @Prop() private readonly label!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly items!: ProductAttr[];
  //   method
  choice (e: MouseEvent) {
    const target = e.target as HTMLElement;
    console.log(target.nodeName);
    if (target.nodeName !== 'SPAN') {
      return;
    }
    this.Value = this.items[target.dataset.key as string].Name;
    this.RealValue = this.items[target.dataset.key as string].Id;
    this.Current = target.dataset.key as string;
    this.$emit('input', this.RealValue);
  }
  //   created
  created () {
    if (this.items.length > 0) {
      this.Value = this.items[0].Name;
      this.RealValue = this.items[0].Id;
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('items', { deep: true })
  onItemsChange () {
    if (this.items.length > 0) {
      this.Value = this.items[0].Name;
      this.RealValue = this.items[0].Id;
      this.$emit('input', this.RealValue);
    }
  }
}
