import { render, staticRenderFns } from "./InsBreadcrumb.vue?vue&type=template&id=3f640504&scoped=true&"
import script from "./InsBreadcrumb.vue?vue&type=script&lang=ts&"
export * from "./InsBreadcrumb.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f640504",
  null
  
)

export default component.exports