










import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class EdProductShare extends Vue {
  get h () {
    return window.location.href;
  }
  whatsApp () {
    var u = location.href;
    var t = document.title;
    window.location.href = 'https://api.whatsapp.com/send?&text=' + encodeURIComponent(t) + encodeURIComponent('\n\n' + u);
  }
}
