
































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Comments from '@/model/Comments';
import { Loading } from 'element-ui';
@Component
export default class InsComments extends Vue {
    // private items: Comments[] = [];
    private showItems: Comments[] = [];
    private LoadingInstance!: any;
    private tips:boolean = true;
    private CurrentPage: number = 1;
    private TotalRecord: number = 0;
    private pageNumber: number = 2;
    @Prop() ProductSku!: string;
    created () {
      // let date = new Date();
      // let result = [
      //   {
      //     username: 'delin',
      //     level: 5,
      //     agree: 1,
      //     icon: '/static/header_icons/1.jpg',
      //     content: '真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！真的好好用啊！！！',
      //     date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      //   },
      //   {
      //     username: 'huang',
      //     level: 4,
      //     agree: 0,
      //     icon: '/static/header_icons/2.jpg',
      //     content: '真的好好用啊！！！',
      //     date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      //   },
      //   {
      //     username: 'max',
      //     level: 3,
      //     agree: 1,
      //     icon: '/static/header_icons/3.jpg',
      //     content: '真的好好用啊！！！',
      //     date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      //   },
      //   {
      //     username: 'helloworld',
      //     level: 4,
      //     agree: 1,
      //     icon: '/static/header_icons/4.jpg',
      //     content: '真的好好用啊！！！',
      //     date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      //   }
      // ];
      // result.forEach((item) => {
      //   this.items.push(new Comments(item));
      // });
      // this.showItems = this.items.slice(0, 2);
      this.$Api.comments.getProductComments({
        Sku: this.ProductSku,
        Page: this.CurrentPage,
        PageSize: this.pageNumber
      }).then((result) => {
        this.showItems = result.Comments;
        this.TotalRecord = result.TotalRecord;
      });
    }
    mounted () {
      this.$nextTick(() => {
        let comments = this.$refs.comments as HTMLElement;
        // comments.style.height = comments.offsetHeight + 'px';
      });
    }
    updated () {
    }
    handleLoad (e) {
      if (!this.tips) return;
      this.LoadingInstance = Loading.service({
        target: this.$refs.load as any,
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
      setTimeout(() => {
        this.load();
        this.LoadingInstance.close();
      }, 2000);
    }
    load () {
      if (this.CurrentPage * this.pageNumber < this.TotalRecord) this.CurrentPage++; else this.tips = false;
    }
    @Watch('CurrentPage')
    onPageChange (o, n) {
    //   this.showItems = this.items.slice((this.currentPage - 1) * this.pageNumber, this.currentPage * this.pageNumber);
    //   this.$nextTick(() => { 目前做不到高度变化时动画
    //     let outer = this.$refs.outer as HTMLElement;
    //     outer.style.height = 'auto';
    //     outer.style.height = outer.offsetHeight + 'px';
    //   });
      this.$Api.comments.getProductComments({
        Sku: this.ProductSku,
        Page: this.CurrentPage,
        PageSize: this.pageNumber
      }).then((result) => {
        this.showItems = this.showItems.concat(result.Comments);
        this.TotalRecord = result.TotalRecord;
      });
    }
};
