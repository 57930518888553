










import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component({
  components: {
    inPage: () => import(/* webpackChunkName: "product" */ '@/components/base/pc/InsPage.vue'),
    inProductCat: () => import(/* webpackChunkName: "product" */ '@/components/NaBusiness/pc/product/NaProductCat.vue')
  }
})
export default class InsProduct extends Vue {
//   private Items: YouWouldLike[] = [];
//   private PageNumber:number = Vue.prototype.userAgent === 'mobile' ? 2 : 4;
  private bannerImage = '/static/Image/pc/product/catTitle.jpg';
  created () {
    if (this.catId === '11') { if (this.$Storage.get('locale') === 'S') { this.bannerImage = '/static/Image/pc/product/productCatBannerGiftS.jpg'; } else { this.bannerImage = '/static/Image/pc/product/productCatBannerGiftT.jpg'; } }
    this.$HiddenLayer();
    this.$store.dispatch('setCatId', this.$route.query.catId);
  }
  @Watch('$route', { deep: true })
  onRouteChange (n, o) {
    this.$store.dispatch('setCatId', this.$route.query.catId);
  }
  get Name () {
    return this.$route.query.name;
  }
  get catId () {
    return String(this.$route.params.catId);
  }
  @Watch('catId')
  onCatIdChange () {
    if (this.catId === '11') {
      if (this.$Storage.get('locale') === 'S') { this.bannerImage = '/static/Image/pc/product/productCatBannerGiftS.jpg'; } else { this.bannerImage = '/static/Image/pc/product/productCatBannerGiftT.jpg'; }
    } else this.bannerImage = '/static/Image/pc/product/catTitle.jpg';
  }
}
