











import inComments from '@/components/business/mobile/product/InsComments.vue';
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component({ components: { inComments } })
export default class PkProductDesc extends Vue {
    private IsDetail:boolean = true;
    @Prop() private skuSource!:string;
    @Prop() private tabSource!:string;
    get ProductSku () {
      if (this.skuSource) return this.$store.state[this.skuSource];
      else throw new Error('Edtoys產品詳情組件：要求你設置sku數據源');
    }
    get Tabs () {
      if (this.tabSource) return this.$store.state[this.tabSource];
      else throw new Error('Edtoys產品詳情組件：要求你設置tab數據源');
    }
}
