
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inProductCat from '@/components/business/mobile/product/InsProductCat.vue';
import YouWouldLike from '@/model/youWouldLike';
import Catalogs from '@/model/Catalogs';
@Component({ components: { inProductCat } })
export default class InsProductCat extends Vue {
  // private catalogs: Catalogs[] = [];
  private catalogs = {};
  private callCatalogs!:Promise<any>;
  private bannerImage = '/static/Image/mobile/product/productCatBanner.jpg';
  created () {
    if (this.catId === '11') { if (this.$Storage.get('locale') === 'S') { this.bannerImage = '/static/Image/mobile/product/productCatBannerGiftS.jpg'; } else { this.bannerImage = '/static/Image/mobile/product/productCatBannerGiftT.jpg'; } }
    this.$HiddenLayer();
    this.callCatalogs = this.$Api.product.getAttrList2();
    let c = {};
    this.callCatalogs.then((result) => {
      result.Catalogs.forEach(element => {
        c[element.Id] = {
          Name: element.Name,
          Remark: element.Remark
        };
        element.Children.forEach(ce => {
          c[ce.Id] = {
            Name: ce.Name,
            Remark: ce.Remark
          };
          ce.Children.forEach(ge => {
            c[ge.Id] = {
              Name: ge.Name,
              Remark: ge.Remark
            };
          });
        });
      });
      this.catalogs = c;
      if (!this.$route.params.catId) this.$store.dispatch('setCatId', this.catalogs[0].Id);
    });
  }
  selectCatalogs (e) {
    let target = e.target as HTMLElement;
    if (target.className === 'cat_item') {
      this.$router.push({
        name: 'productCat',
        path: '/product/cat',
        params: {
          catId: target.dataset.keys as string
        }
      });
    }
  }
  get catId () {
    return String(this.$route.params.catId);
  }
  get cname () {
    return this.catalogs[this.catId];
  }
  @Watch('catId')
  onCatIdChange () {
    if (this.catId === '11') {
      if (this.$Storage.get('locale') === 'S') { this.bannerImage = '/static/Image/mobile/product/productCatBannerGiftS.jpg'; } else { this.bannerImage = '/static/Image/mobile/product/productCatBannerGiftT.jpg'; }
    } else this.bannerImage = '/static/Image/pc/product/catTitle.jpg';
  }
}
