









import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsBreadcrumb extends Vue {
    @Prop() private breadCrumbSource!:string;
    get breadCrumb () {
      return this.$store.state[this.breadCrumbSource];
    }
}
